import React, { useContext, useState, useEffect, useRef } from "react";

import { OrderContext } from "../../src/order-context";

const CustomerNote = ({ showNotePopup, setShowNotePopup }) => {
  const { order, setOrder, activeTable} =
    useContext(OrderContext);

  const [currentOrder, setCurrentOrder] = useState([]);
  const note_ref = useRef();

  const hideNotePopup = () => {
    setShowNotePopup((prevState) => ({
      ...prevState,
      status: false,
      id: "",
    }));

  }

  return (
    <div className="fixed top-0 w-[100vw] h-[50vh] z-[10000] backdrop-blur-sm">
      <div className="border-2  border-primary absolute top-1/2 bg-secondary w-full  p-6 px-2 flex flex-col shadow-2xl  left-[50%] translate-x-[-50%] rounded-xl ">
        <div className="relative">
          <h2 className="font-bold text-xl mb-4">Customer Note</h2>
          <span
            onClick={hideNotePopup}
            className="absolute top-[-30%] right-[3%] "
          >
            <i className="text-3xl fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>

        <div className="overflow-scroll max-h-[85vh] w-full pb-7">
          <textarea
            ref={note_ref}
            id="w3review"
            name="w3review"
            className="border-2 w-full"
            rows="4"
            cols="50"
          ></textarea>
        </div>
        <div className="bg-[#fff] w-full sticky bottom-0">
          <button
            onClick={() => {
              let updatedOrder = order.map((odr) => {
                if (
                  odr.product.id === showNotePopup.id &&
                  activeTable.id === odr.table_id
                ) {
                  odr.customer_note = note_ref.current.value;
                }
                return odr;
              });

              setOrder(updatedOrder);
              hideNotePopup()

            }}
            className="w-[90%] mx-auto my-4 bg-primary rounded-md text-secondary p-3  "
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerNote;
