import React from 'react';

const Header = () => {
  return (
    <header className="sticky z-[100] top-0 shadow-md py-4 text-secondary bg-primary">
      <h1 className="text-3xl font-bold  ">
        Scrapyard Order Taker
      </h1>
    </header>
  );
};

export default Header;
