import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { OrderContext } from "../../src/order-context";
import CustomerNote from "./customerNote";
import LoadingSpinner from "./ui/loadingSpinner";
import { notifyFailure, notifyOrder } from "./ui/toastAlert";

const OrderPreview = ({ setActiveScreen, setShowOrderPreview }) => {
  const { order, setOrder } = useContext(OrderContext);
  const { activeTable } = useContext(OrderContext);
  const [messageHistory, setMessageHistory] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const loader = useRef();

  const [socketUrl, setSocketUrl] = useState(
    process.env.REACT_APP_WEBSOCKET_URL
  );
  const [currentOrder, setCurrentOrder] = useState([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  const [showNotePopup, setShowNotePopup] = useState({ status: false, id: "" });

  const statusCheck = () => {
    if (loader.current) {
      notifyFailure();
      setShowOrderPreview(false);
      setActiveScreen("tableScreen");
    }
  };

  const handleClickSendMessage = useCallback(() => {
    console.log(currentOrder,'CURRENT ORDER')
    let data = {
      action: "new_order",
      data: currentOrder,
    };
    setLoadingStatus((prevState) => !prevState);

    sendMessage(JSON.stringify(data));
    setTimeout(() => {
      statusCheck();
    }, [3000]);
  }, [currentOrder]);
  useEffect(() => {
    if (lastMessage !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        // console.log("Received message:", JSON.parse(reader.result));
        const response = JSON.parse(reader.result);
        setMessageHistory((prev) => [...prev, response]);
        if (
          response.status === "success" &&
          response.action === "order_response"
        ) {
          let updatedOrder = order.filter(
            (od) => od.table_id !== activeTable.id
          );
          setOrder(updatedOrder);
          setLoadingStatus(false);
          setShowOrderPreview(false);
          setActiveScreen("tableScreen");
          notifyOrder();
        }
      };
      reader.readAsText(lastMessage.data);
    }
  }, [lastMessage]);

  useEffect(() => {
    let currOrder = order.filter((item) => item.table_id === activeTable.id);

    setCurrentOrder(currOrder);
  }, [order, activeTable.id]);

  const handleQuantity = (productId, inc) => {
    let updatedOrder = order.map((item) => {
      if (item.product.id === productId && activeTable.id === item.table_id) {
        return {
          ...item,
          quantity: inc ? item.quantity + 1 : item.quantity - 1, // Update the quantity
        };
      }
      return item;
    });
    setOrder(updatedOrder);
  };
  return (
    <div className="fixed top-0 w-[100vw] h-[100vh] z-[10000] backdrop-blur-sm">
      <div className="border-2  border-primary absolute top-0 bg-secondary w-full  p-6 px-2 flex flex-col shadow-2xl  left-[50%] translate-x-[-50%] rounded-xl min-h-[100vh]">
        <div className="relative">
          <h2 className="font-bold text-xl mb-4">Order Preview</h2>
          <span
            onClick={() => {
              setShowOrderPreview(false);
            }}
            className="absolute top-[-30%] right-[3%] "
          >
            <i className="text-3xl fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>

        <div className="overflow-scroll max-h-[85vh] w-full pb-14">
          <table className="w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="py-2 px-2 border-b">Name</th>
                {/* <th className="py-2 px-2 border-b">Edit</th> */}
                <th className="py-2 px-2 border-b">Qty</th>
                <th className="py-2 px-2 border-b">del</th>
                <th className="py-2 px-2 border-b">Note</th>
              </tr>
            </thead>

            <tbody className="overflow-y-scroll ">
              {currentOrder.length > 0 &&
                currentOrder.map((item, key) => {
                  return (
                    <tr key={item.product.id}>
                      <td className="py-2 px-2 text-sm border-b w-[42%]">
                        {item.product.display_name}
                        {item.customer_note && (
                          <span className="block font-bold">
                            *{item.customer_note}
                          </span>
                        )}
                      </td>
                      <td className="py-2 px-2 text-sm border-b">
                        <button
                          disabled={item.quantity <= 1 && true}
                          onClick={() => {
                            handleQuantity(item.product.id, false);
                          }}
                          className="p-2 text-xl m-2 b-2 shadow-sm bg-[#f0f0f0] rounded-md "
                        >
                          {" "}
                          <i className="fa text-sm fa-minus"></i>
                        </button>

                        {item.quantity}

                        <button
                          onClick={() => {
                            handleQuantity(item.product.id, true);
                          }}
                          className="p-2 text-xl m-2 b-2 shadow-sm bg-[#f0f0f0] rounded-md "
                        >
                          {" "}
                          <i className="fa text-sm fa-plus"></i>{" "}
                        </button>
                      </td>
                      {/* <td className="py-2 px-2 text-sm border-b">
                            {item.quantity}
                          </td> */}
                      <td
                        className="py-2 px-2 text-sm border-b"
                        onClick={() => {
                          setOrder(
                            order.filter(
                              (od) => od.product.id !== item.product.id
                            )
                          );
                        }}
                      >
                        <i
                          className="fa fa-trash shadow-xl rounded-xl text-sm "
                          aria-hidden="true"
                        ></i>
                      </td>
                      <td
                        onClick={() => {
                          setShowNotePopup((prevState) => ({
                            ...prevState,
                            status: true,
                            id: item.product.id,
                          }));
                        }}
                        className="py-2 px-2 text-sm border-b"
                      >
                        <i
                          className="fa fa-pencil shadow-xl rounded-xl text-sm "
                          aria-hidden="true"
                        ></i>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="bg-[#fff] w-full sticky bottom-0">
          {loadingStatus ? (
            <button
              onClick={handleClickSendMessage}
              disabled="true"
              className="w-[90%] mx-auto my-4 bg-primary rounded-md text-secondary p-3  "
            >
              <div ref={loader}>
                <LoadingSpinner />{" "}
              </div>
            </button>
          ) : (
            <button
              onClick={handleClickSendMessage}
              disabled={readyState !== ReadyState.OPEN}
              className="w-[90%] mx-auto my-4 bg-primary rounded-md text-secondary p-3  "
            >
              Place Order
            </button>
          )}
        </div>
      </div>
      {showNotePopup.status && (
        <CustomerNote
          showNotePopup={showNotePopup}
          setShowNotePopup={setShowNotePopup}
        />
      )}
    </div>
  );
};

export default OrderPreview;
