import React, { useState, useContext, useEffect } from "react";
import ProductCard from "./productCard";
import ProductSearch from "./productSearch";
import { OrderContext } from "../../src/order-context";
import OrderPreview from "./orderPreview";
import { ToastAlert } from "./ui/toastAlert";
import OrderHistory from "./orderHistory";

const ProductScreen = ({ setActiveScreen }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showOrderPreview, setShowOrderPreview] = useState(false);
  const [showOrderHistory, setShowOrderHistory] = useState(false);

  let prevCat, currCat;
  const { activeTable, order, setOrder, productList } =
    useContext(OrderContext);
  const currentOrder = order.filter((od) => od.table_id === activeTable.id);
  const orderCount = currentOrder.length ? currentOrder.length : 0;

  return (
    <>
      {productList.length ? (
        <div className="w-[95%] mx-auto flex justify-center items-center flex-wrap  gap-2 mt-8 sm:mt-10">
          <h2 className=" text-3xl font-bold w-full text-left">
            <div className="flex justify-between">
              <button
                onClick={() => {
                  setActiveScreen("tableScreen");
                }}
                className=" bg-primary rounded-md text-secondary p-3"
              >
                <i className="fa fa-arrow-left text-xl"></i>
              </button>
              <h1>{activeTable.table_name}</h1>
              <div className="flex  gap-4">
                <button
                  disabled={activeTable.orders.length < 1 && true}
                  onClick={() => {
                    setShowOrderHistory(true);
                  }}
                  className="relative cursor-pointer bg-primary rounded-md text-secondary p-3"
                >
                  <i className="fa fa-eye text-xl"></i>
                  <span className="bg-[crimson]  rounded-full p-1 px-2 absolute top-[-30%] right-[-2%] text-sm">
                    {activeTable.orders}
                  </span>
                </button>

                <button
                  disabled={orderCount < 1 && true}
                  onClick={() => {
                    setShowOrderPreview(true);
                  }}
                  className="relative cursor-pointer bg-primary rounded-md text-secondary p-3"
                >
                  <i className="fa fa-sticky-note text-xl"></i>
                  <span className="bg-[crimson]  rounded-full p-1 px-2 absolute top-[-30%] right-[-2%] text-sm">
                    {orderCount}
                  </span>
                </button>
              </div>
            </div>
          </h2>
          <div className=" w-full">
            <ProductSearch
              productList={productList}
              filteredProducts={filteredProducts}
              setFilteredProducts={setFilteredProducts}
            />
          </div>

          <div className="flex justify-center overflow-y-scroll max-h-[68vh] sm:max-h-full items-stretch w-full flex-wrap ">
            {(filteredProducts.length > 0 ? filteredProducts : productList).map(
              (product, key) => {
                if (key == 0) {
                  prevCat = product.categ.name;
                  currCat = product.categ.name;
                } else if (prevCat != product.categ.name && prevCat != "") {
                  prevCat = product.categ.name;
                  currCat = product.categ.name;
                }
                if (product.active) {
                  return (
                    <div key={product.id} className="contents">
                      {currCat !== "" && (
                        <h1 className="w-full text-semibold bg-[#f0f0f0] mt-8">
                          {prevCat}
                        </h1>
                      )}
                      {(currCat = "")}

                      <ProductCard
                        setShowOrderPreview={setShowOrderPreview}
                        setOrder={setOrder}
                        order={order}
                        key={product.id}
                        product={product}
                      />
                    </div>
                  );
                }
              }
            )}
          </div>
          {showOrderPreview && (
            <OrderPreview
              setActiveScreen={setActiveScreen}
              setShowOrderPreview={setShowOrderPreview}
            />
          )}
          {showOrderHistory && (
            <OrderHistory setShowOrderHistory={setShowOrderHistory} />
          )}
        </div>
      ) : (
        <h1>Loading</h1>
      )}
    </>
  );
};

export default ProductScreen;
