import React, { useContext, useState, useEffect } from "react";

import { OrderContext } from "../../src/order-context";

const OrderHistory = ({ setShowOrderHistory }) => {
  

  const { activeTable } = useContext(OrderContext);
  const [orderHistoryData, setOrderHistoryData] = useState([]);

  useEffect(() => {
    fetchOrderDrafts(activeTable.id);
  }, []);

  async function fetchOrderDrafts(tableId) {
    const url = `${process.env.REACT_APP_ODOO_BACKEND_URL}/pos/order_drafts`; // Update with your Odoo instance URL

    const requestData = {
      params: {
        table_id: Number(tableId),
      },
    };

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: headers,

      body: JSON.stringify(requestData),
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      const result = await response.json();
      setOrderHistoryData(result.result);
      console.log(result.result, "ORDER Fetched");
      return result;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  return (
    <div className="fixed top-0 w-[100vw] h-[100vh] z-[10000] backdrop-blur-sm">
      <div className="border-2  border-primary absolute top-0 bg-secondary w-full  p-6 px-2 flex flex-col shadow-2xl  left-[50%] translate-x-[-50%] rounded-xl min-h-[100vh]">
        <div className="relative">
          <h2 className="font-bold text-xl mb-4">Order Preview</h2>
          <span
            onClick={() => {
              setShowOrderHistory(false);
            }}
            className="absolute top-[-30%] right-[3%] "
          >
            <i className="text-3xl fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>

        <div className="overflow-scroll max-h-[85vh] w-full pb-14">
          <div className="w-full bg-white border border-gray-200">
            <div className="flex bg-gray-100 border-b border-gray-200">
              <div className="flex-1 py-2 px-2 border-r border-gray-200 font-bold">
                Name
              </div>
              <div className="flex-1 py-2 px-2 border-r border-gray-200 font-bold">
                Date
              </div>
            </div>

            <div className=" overflow-y-auto mu-2">
              {orderHistoryData.length && orderHistoryData.map((item, key) => (
                <div key={key} className="relative">
                  {/* Hidden checkbox */}
                  <input
                    type="checkbox"
                    id={`toggle-${key}`}
                    className="hidden"
                  />

                  {/* Label as clickable header */}
                  <label
                    htmlFor={`toggle-${key}`}
                    className="flex border-b border-gray-200 cursor-pointer"
                  >
                    <div className="flex-1 py-2 px-2 text-sm border-gray-200">
                      {activeTable.table_name}
                    </div>
                    <div className="flex-1 py-2 px-2 text-sm border-gray-200">
                      {item.order_details.date_order.toLocaleString()}
                    </div>
                  </label>

                  {/* Collapsible Content */}
                  <div className="overflow-hidden  border-primary rounde-md border-2 transition-max-height bg-[#fff] text-[#000] rounded-xl   duration-300 ease-in-out max-h-0 checkbox:checked:max-h-screen">
                    <div className="flex  ">
                      <div className="flex-1 py-2 px-2  border-gray-200 font-bold">
                        Product
                      </div>
                      <div className="flex-1 py-2 px-2  border-gray-200 font-bold">
                        Quantity
                      </div>
                      <div className="flex-1 py-2 px-2  border-gray-200 font-bold">
                        Amount
                      </div>
                    </div>
                    {item.order_details.order_lines.map((line, index) => (
                      <div className="flex   border-gray-200">
                        <div className="flex-1 py-2 px-2  border-gray-200 font-bold">
                          {line.product_id[1]}
                        </div>
                        <div className="flex-1 py-2 px-2  border-gray-200 font-bold">
                          {line.qty}
                        </div>
                        <div className="flex-1 py-2 px-2  border-gray-200 font-bold">
                          ₹{line.price_subtotal}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
