import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function notify(details,qtyUpdate) {
  toast.info(<div className="font-bold">{details.product.display_name} was added <br/> Quantity:{qtyUpdate ? qtyUpdate : details.quantity}</div>,{
    className:'text-left '
  });
}
export function notifyOrder() {
  toast.success(<div className="font-bold">Order placed successfully!</div>,{
    className:'text-left '
  });
}

export function notifyFailure() {
  toast.error(<div className="font-bold">Failed to create order.</div>,{
    className:'text-left '
  });
}

export function ToastAlert() {
  return (
    <div>
      <ToastContainer autoClose={800}  theme="light"/>
    </div>
  );
}
