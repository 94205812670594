import React, { useContext } from "react";
import { OrderContext } from "../../src/order-context";

import { notify } from "./ui/toastAlert";

const ProductCard = ({ product }) => {
  const { activeTable } = useContext(OrderContext);
  const { order, setOrder } = useContext(OrderContext);
  const addToOrder = (product) => {
    let orderDetails = {
      product: product,
      table_id: activeTable.id,
      quantity: 1, // Default quantity is 1
    };

    let updatedOrder = order.map((item) => {
      if (item.product.id === product.id && activeTable.id === item.table_id) {
        // Check if the product already exists
        notify(orderDetails, item.quantity + 1);
        return {
          ...item,
          quantity: item.quantity + 1, // Update the quantity
        };
      }
      return item;
    });

    // If the product was not found in the order, add it as a new item
    if (
      !updatedOrder.some(
        (item) =>
          item.product.id === product.id && item.table_id === activeTable.id
      )
    ) {
      notify(orderDetails);
      updatedOrder = [...updatedOrder, orderDetails];
    }

    setOrder(updatedOrder);
    
  };

  return (
    <div
      onClick={() => addToOrder(product)}
      className="cursor-pointer relative mt-6 flex min-w-[8rem] mx-2 flex-col  rounded-lg border border-gray-100 bg-white shadow-md"
    >
      <div className="relative mx-3 mt-3 h-100 border-2 flex justify-center items-center rounded-sm">
        <h2 className="text-sm max-w-[7rem] tracking-tight text-slate-900 py-10 px-6">
          {product.display_name}
        </h2>
      </div>
      <div className="mt-4 px-5 pb-5">
        <div></div>
        <div className="mt-2  flex items-center justify-between">
          <p>
            <span className="text-md font-bold text-slate-900">
              ₹ {product.lst_price}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
