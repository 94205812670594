import React, { useEffect, useState, useContext } from "react";
import { OrderContext } from "../../src/order-context";

const TableScreen = ({ setActiveScreen }) => {
  const [tableList, setTableList] = useState([]);
  const [activeFloor, setActiveFloor] = useState(1);

  const { setActiveTable } = useContext(OrderContext);
  useEffect(() => {
    fetchData();
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setInterval(() => {
      console.log("timeout Table Count");
      fetchData();
    }, 5000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearInterval(timeoutId);
  }, []); // Empty dependency array ensures the effect runs only once

  const fetchData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "session_id=2c7581c655bbe9e0c16d92d253ff582c25ed489f;frontend_lang=en_US; "
    );

    const raw = JSON.stringify({
      params: {
        pos_config_id: 1,
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_ODOO_BACKEND_URL}/my_module/get_tables_order_count`,
      requestOptions
    )
      .then(async (result) => {
        let res = await result.json();
        setTableList(res.result);
        // res.result.map(odr => {
        //   if(odr.orders.length > 0){
        //     console.log(odr)

        //   }
        // })
      })
      .catch((error) => console.error(error));
  };

  const addOrdertoTable = (table) => {
    setActiveTable((prevState) => ({
      ...prevState,
      id: table.id,
      table_name: table.table.display_name,
      orders: table.orders,
    }));
    setActiveScreen("productScreen");
  };
  return (
    <>
      {tableList.length > 0 ? (
        <div className="w-full my-8 flex justify-center items-center flex-wrap  gap-10 mt-10">
          <h2 className="text-center text-3xl font-bold w-[60%] sm:text-left">
            Table Screen
          </h2>
          <div className="w-[90%] rounded-3xl  border-2 flex justify-between ">
            <button
              onClick={() => setActiveFloor(1)}
              className={`rounded-3xl py-2 font-bold text-lg w-1/2 ${
                activeFloor === 1 ? "bg-primary text-white" : "text-black"
              }`}
            >
              Roof Top
            </button>
            <button
              onClick={() => setActiveFloor(0)}
              className={`rounded-3xl py-2 font-bold text-lg w-1/2 ${
                activeFloor === 0 ? "bg-primary text-white" : "text-black"
              }`}
            >
              Ground Floor
            </button>
          </div>
          <div className="flex justify-center items-center gap-2 w-[90%] flex-wrap ">
            {tableList.map((table) => {
              if (
                (activeFloor === 1 && table.table.floor_id[0] === 2) ||
                (activeFloor === 0 && table.table.floor_id[0] === 1)
              ) {
                return;
              }
              return (
                <div
                  onClick={() => addOrdertoTable(table)}
                  className="cursor-pointer relative w-[30%] sm:w-[10%] text-wtfont bg-primary rounded-xl h-[100px] shadow-2xl flex justify-center items-center text-xl flex-col font-semibold"
                  key={table.id}
                >
                  {table.table.display_name} 
                  <span
                    className={`absolute top-[-5%] right-[-1%]   px-2 text-lg rounded-full  bg-blfont `}
                  >
                    {table.orders > 0 ? table.orders : ""}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <h1>Loading Data...</h1>
      )}
    </>
  );
};

export default TableScreen;
