import logo from "./logo.svg";
import "./App.css";
import TableScreen from "./components/tableScreen";
import Header from "./components/layout/header";
import { useState } from "react";
import ProductScreen from "./components/productScreen";

import GetTablesOrderCount from "./components/fetchData";
import FetchComponent from "./components/fetchData";
import { ToastAlert } from "./components/ui/toastAlert";

function App() {
  const [activeScreen, setActiveScreen] = useState("tableScreen");
  return (
    <div className="App ">
      <Header />

      {activeScreen === "tableScreen" ? (
        <TableScreen setActiveScreen={setActiveScreen} />
      ) : (
        <ProductScreen setActiveScreen={setActiveScreen} />
      )}
      <ToastAlert/>
    </div>
  );
}

export default App;
