import React, { createContext, useState, useEffect } from "react";

// Create the context
const OrderContext = createContext();

// Create a provider component
const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState([]);
  const [activeTable, setActiveTable] = useState({});
  const [productList, setProductList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        custom_search_params: {
          domain: [["name", "ilike", "product"]],
          fields: ["name", "list_price", "qty_available"],
          limit: 10,
        },
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_ODOO_BACKEND_URL}/my_module/get_products`,
        requestOptions
      )
        .then(async (response) => {
          let res = await response.json();

          const sortedProducts = res.result.sort((a, b) => {
            const nameA = a.categ.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.categ.name.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          setProductList(sortedProducts);
        })

        .catch((error) => console.error(error));
    };
    fetchData();
  }, []);

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        activeTable,
        setActiveTable,
        productList,
        setProductList,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider };
